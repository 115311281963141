import { useEffect, useState } from 'react';
import Loader from 'react-loaders';
import { useRef } from 'react';
import emailjs from '@emailjs/browser';
import AnimatedLetters from '../../AnimatedLetters';
import './index.scss'

const Contact = () => { 
    const [letterClass, setLetterClass] = useState('text-animate')
    const form = useRef()

    useEffect( () => {
        setTimeout(() => {
         setLetterClass('text-animate-hover')
       }, 3000)
     }, [])

     const sendEmail = (e) => {
        e.preventDefault()

        emailjs
        .sendForm('service_xif2y5i','template_yo5xlfl',form.current,'TpfuuqgTELesfxoz2')
        .then(
            () => {
            alert('Email sent successfully');
            window.location.reload(false);
        },
        () => {
            alert('Failed to send the message');
        }
        )
     }

    return (
        <>
        <div className="container contact-page">
            <div className='text-zone'>
                <h1>

                    <AnimatedLetters 
                    letterClass={letterClass}
                    strArray={['C','o','n','t','a','c','t',' ','m','e']} 
                    idx={15}
                    />
                </h1>

                <p>I am interested in a front end position, if you have other requests or questions, do not hesitate to contact me using below form either.</p>

                <div className='contact-form'>
                    <form ref={form} onSubmit={sendEmail}>
                        <ul>
                            <li className='half'>
                                <input type='text' name='name' placeholder='Name' required/>
                            </li>
                            <li className='half'>
                                <input type='email' name='email' placeholder='Email' required/>
                            </li>
                       
                        <li>
                            <input placeholder='Subject' type='text' name="subject" required />
                        </li>
                        <li>
                            <textarea placeholder='Message' name='message' required></textarea>
                        </li>
                        <li>
                            <input type="submit" className='flat-button' value="SEND" />
                        </li>
                        </ul>
                    </form>
                </div>
            </div>

        </div>
        <Loader type="line-scale" />

        </>
    
    
    
    ) 


}

export default Contact;