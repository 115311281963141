import Loader from 'react-loaders';
import './index.scss';
import AnimatedLetters from '../../AnimatedLetters';
import { useEffect, useState } from 'react';

const Contact = () => {

    const [letterClass, setLetterClass] = useState('text-animate');

    useEffect( () => {
        setTimeout(() => {
         setLetterClass('text-animate-hover')
       }, 3000)
     }, [])

    return (
       <>
        <div className="container contact-page">
            <div className='text-zone'>
            <h1>
                    <AnimatedLetters
                        letterClass={letterClass}
                        strArray={['P','o','r','t','f','o','l','i','o', ' ','Under Constrction']}
                        idx={15}
                    />
            </h1>
            </div>

        
        </div>
        <Loader type="line-scale" />
    </>
       
    )
}

export default Contact;